import RobloxEventTracker from '../../../../common/eventTracker';
import { FEATURE_NAME, METRICS_CONSTANTS } from '../app.config';
import { ActionType, MediaType } from '../interface';

/**
 * A class encapsulating the metrics fired by this web app.
 */
export class MetricsServiceDefault {
  private appType: string;

  private actionType: ActionType;

  private solveTimeSequenceName: string;

  constructor(actionType: ActionType, appType: string | undefined) {
    this.appType = appType || 'unknown';
    this.actionType = actionType;
    this.solveTimeSequenceName = `${this.actionType}_${FEATURE_NAME}_${METRICS_CONSTANTS.sequence.solveTime}`;
  }

  fireInitializedEvent(): void {
    if (!RobloxEventTracker) {
      return;
    }
    const eventName = `${this.actionType}_${FEATURE_NAME}_${METRICS_CONSTANTS.event.initialized}`;
    RobloxEventTracker.fireEvent(eventName, `${eventName}_${this.appType}`);
    RobloxEventTracker.start(
      this.solveTimeSequenceName,
      `${this.solveTimeSequenceName}_${this.appType}`
    );
  }

  fireVerifiedEvent(mediaType: MediaType | null): void {
    if (!RobloxEventTracker) {
      return;
    }
    if (mediaType === null) {
      return;
    }
    const eventName = `${this.actionType}_${FEATURE_NAME}_${METRICS_CONSTANTS.event.verified}${mediaType}`;
    RobloxEventTracker.fireEvent(eventName, `${eventName}_${this.appType}`);
    RobloxEventTracker.endSuccess(
      this.solveTimeSequenceName,
      `${this.solveTimeSequenceName}_${this.appType}`
    );
  }

  fireInvalidatedEvent(): void {
    if (!RobloxEventTracker) {
      return;
    }
    const eventName = `${this.actionType}_${FEATURE_NAME}_${METRICS_CONSTANTS.event.invalidated}`;
    RobloxEventTracker.fireEvent(eventName, `${eventName}_${this.appType}`);
    RobloxEventTracker.endFailure(
      this.solveTimeSequenceName,
      `${this.solveTimeSequenceName}_${this.appType}`
    );
  }

  fireAbandonedEvent(): void {
    if (!RobloxEventTracker) {
      return;
    }
    const eventName = `${this.actionType}_${FEATURE_NAME}_${METRICS_CONSTANTS.event.abandoned}`;
    RobloxEventTracker.fireEvent(eventName, `${eventName}_${this.appType}`);
    RobloxEventTracker.endCancel(
      this.solveTimeSequenceName,
      `${this.solveTimeSequenceName}_${this.appType}`
    );
  }
}

/**
 * An interface encapsulating the metrics fired by this web app.
 *
 * This interface type offers future flexibility e.g. for mocking the default
 * metrics service.
 */
export type MetricsService = {
  [K in keyof MetricsServiceDefault]: MetricsServiceDefault[K];
};
