import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { RequestService } from '../../../common/request';
import { TRANSLATION_CONFIG } from './app.config';
import Captcha from './containers/captcha';
import {
  ActionType,
  OnChallengeCompletedCallback,
  OnChallengeDisplayedCallback,
  OnChallengeInvalidatedCallback,
  OnModalChallengeAbandonedCallback
} from './interface';
import { CaptchaContextProvider } from './store/contextProvider';

type Props = {
  actionType: ActionType;
  appType: string | null;
  dataExchangeBlob: string;
  unifiedCaptchaId: string;
  renderInline: boolean;
  requestService: RequestService;
  onChallengeDisplayed: OnChallengeDisplayedCallback;
  onChallengeCompleted: OnChallengeCompletedCallback;
  onChallengeInvalidated: OnChallengeInvalidatedCallback;
  onModalChallengeAbandoned: OnModalChallengeAbandonedCallback | null;
} & WithTranslationsProps;

export const App: React.FC<Props> = ({
  actionType,
  appType,
  dataExchangeBlob,
  unifiedCaptchaId,
  renderInline,
  requestService,
  translate,
  onChallengeDisplayed,
  onChallengeCompleted,
  onChallengeInvalidated,
  onModalChallengeAbandoned
}: Props) => {
  return (
    <CaptchaContextProvider
      actionType={actionType}
      appType={appType}
      dataExchangeBlob={dataExchangeBlob}
      unifiedCaptchaId={unifiedCaptchaId}
      renderInline={renderInline}
      requestService={requestService}
      translate={translate}
      onChallengeDisplayed={onChallengeDisplayed}
      onChallengeCompleted={onChallengeCompleted}
      onChallengeInvalidated={onChallengeInvalidated}
      onModalChallengeAbandoned={onModalChallengeAbandoned}>
      <Captcha />
    </CaptchaContextProvider>
  );
};

export default withTranslations(App, TRANSLATION_CONFIG);
