import React from 'react';
import useTwoStepVerificationContext from '../hooks/useTwoStepVerificationContext';

const SUPPORT_URL = 'https://www.roblox.com/info/2sv';

/**
 * A component that displays a Roblox support URL.
 */
const SupportHelp: React.FC = () => {
  const {
    state: { resources }
  } = useTwoStepVerificationContext();

  // IMPORTANT: Do not inject user input into this variable; this content is
  // rendered as HTML.
  const supportLink = resources.Label.NeedHelpContactSupport(
    `<a class="text-name text-footer contact" href="${SUPPORT_URL}" target="_blank">${resources.Label.RobloxSupport}</a>`
  );

  // eslint-disable-next-line react/no-danger
  return <span className='text-footer' dangerouslySetInnerHTML={{ __html: supportLink }} />;
};
export default SupportHelp;
