// This file contains the public interface types for this component. Since this
// component uses TS strict mode, which other TS components may not, we keep
// the types for the public interface separate in order to avoid compilation
// errors arising from the strict mode mismatch.

import * as Captcha from '../captcha/interface';
import * as ProofOfWork from '../proofOfWork/interface';
import * as Reauthentication from '../reauthentication/interface';
import * as SecurityQuestions from '../securityQuestions/interface';
import * as TwoStepVerification from '../twoStepVerification/interface';

/**
 * Values of the `feature` field when sending a hybrid `navigateToFeature` back
 * to a hybrid challenge client.
 */
export enum HybridTarget {
  CHALLENGE_COMPLETED = 'challengeCompleted',
  CHALLENGE_DISPLAYED = 'challengeDisplayed',
  CHALLENGE_INITIALIZED = 'challengeInitialized',
  CHALLENGE_INVALIDATED = 'challengeInvalidated',
  CHALLENGE_PAGE_LOADED = 'challengePageLoaded',
  CHALLENGE_PARSED = 'challengeParsed'
}

/**
 * The requested challenge to render.
 */
export enum ChallengeType {
  CAPTCHA = 'captcha',
  TWO_STEP_VERIFICATION = 'two-step-verification',
  SECURITY_QUESTIONS = 'security-questions',
  REAUTHENTICATION = 'reauthentication',
  PROOF_OF_WORK = 'proof-of-work'
}

/**
 * The type of data returned from a given challenge type.
 */
export type ChallengeData<T extends ChallengeType> = T extends ChallengeType.TWO_STEP_VERIFICATION
  ? TwoStepVerification.OnChallengeCompletedData
  : T extends ChallengeType.CAPTCHA
  ? Captcha.OnChallengeCompletedData
  : T extends ChallengeType.SECURITY_QUESTIONS
  ? SecurityQuestions.OnChallengeCompletedData
  : T extends ChallengeType.REAUTHENTICATION
  ? Reauthentication.OnChallengeCompletedData
  : T extends ChallengeType.PROOF_OF_WORK
  ? ProofOfWork.OnChallengeCompletedData
  : never;

/**
 * The parameters required to render a challenge.
 */
export type ChallengeParameters = {
  containerId: string;
  hybridTargetToCallbackInputId: { [K in HybridTarget]: string };
};

/**
 * The type of `renderChallengeFromQueryParameters`.
 */
export type RenderChallengeFromQueryParameters = (
  challengeParameters: ChallengeParameters
) => Promise<boolean>;

/**
 * Renders a hybrid challenge in a specific element based on the URL query
 * parameters.
 *
 * A hybrid challenge is one that calls back into a platform's native code by
 * following a designated calling convention.
 */
export declare const renderChallengeFromQueryParameters: RenderChallengeFromQueryParameters;
