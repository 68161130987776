import RobloxEventTracker from '../../../../common/eventTracker';
import { FEATURE_NAME, METRICS_CONSTANTS } from '../app.config';

/**
 * A class encapsulating the metrics fired by this web app.
 */
export class MetricsServiceDefault {
  private appType: string;

  private challengeSolveTimeSequenceName: string;

  private puzzleWorkingTimeSequenceName: string;

  constructor(appType: string | undefined) {
    this.appType = appType || 'unknown';
    this.challengeSolveTimeSequenceName = `${FEATURE_NAME}_${METRICS_CONSTANTS.sequence.challengeSolveTime}`;
    this.puzzleWorkingTimeSequenceName = `${FEATURE_NAME}_${METRICS_CONSTANTS.sequence.puzzleWorkingTime}`;
  }

  fireChallengeInitializedEvent(): void {
    if (!RobloxEventTracker) {
      return;
    }
    const eventName = `${FEATURE_NAME}_${METRICS_CONSTANTS.event.challengeInitialized}`;
    RobloxEventTracker.fireEvent(eventName, `${eventName}_${this.appType}`);
    RobloxEventTracker.start(
      this.challengeSolveTimeSequenceName,
      `${this.challengeSolveTimeSequenceName}_${this.appType}`
    );
  }

  firePuzzleInitializedEvent(): void {
    if (!RobloxEventTracker) {
      return;
    }
    const eventName = `${FEATURE_NAME}_${METRICS_CONSTANTS.event.puzzleInitialized}`;
    RobloxEventTracker.fireEvent(eventName, `${eventName}_${this.appType}`);
    RobloxEventTracker.start(
      this.puzzleWorkingTimeSequenceName,
      `${this.puzzleWorkingTimeSequenceName}_${this.appType}`
    );
  }

  firePuzzleCompletedEvent(): void {
    if (!RobloxEventTracker) {
      return;
    }
    const eventName = `${FEATURE_NAME}_${METRICS_CONSTANTS.event.puzzleCompleted}`;
    RobloxEventTracker.fireEvent(eventName, `${eventName}_${this.appType}`);
    RobloxEventTracker.endSuccess(
      this.puzzleWorkingTimeSequenceName,
      `${this.puzzleWorkingTimeSequenceName}_${this.appType}`
    );
  }

  fireChallengeCompletedEvent(): void {
    if (!RobloxEventTracker) {
      return;
    }
    const eventName = `${FEATURE_NAME}_${METRICS_CONSTANTS.event.challengeCompleted}`;
    RobloxEventTracker.fireEvent(eventName, `${eventName}_${this.appType}`);
    RobloxEventTracker.endSuccess(
      this.challengeSolveTimeSequenceName,
      `${this.challengeSolveTimeSequenceName}_${this.appType}`
    );
  }

  fireChallengeInvalidatedEvent(): void {
    if (!RobloxEventTracker) {
      return;
    }
    const eventName = `${FEATURE_NAME}_${METRICS_CONSTANTS.event.challengeInvalidated}`;
    RobloxEventTracker.fireEvent(eventName, `${eventName}_${this.appType}`);
    RobloxEventTracker.endFailure(
      this.challengeSolveTimeSequenceName,
      `${this.challengeSolveTimeSequenceName}_${this.appType}`
    );
  }

  fireChallengeAbandonedEvent(): void {
    if (!RobloxEventTracker) {
      return;
    }
    const eventName = `${FEATURE_NAME}_${METRICS_CONSTANTS.event.challengeAbandoned}`;
    RobloxEventTracker.fireEvent(eventName, `${eventName}_${this.appType}`);
    RobloxEventTracker.endCancel(
      this.challengeSolveTimeSequenceName,
      this.puzzleWorkingTimeSequenceName,
      `${this.challengeSolveTimeSequenceName}_${this.appType}`,
      `${this.puzzleWorkingTimeSequenceName}_${this.appType}`
    );
  }
}

/**
 * An interface encapsulating the metrics fired by this web app.
 *
 * This interface type offers future flexibility e.g. for mocking the default
 * metrics service.
 */
export type MetricsService = {
  [K in keyof MetricsServiceDefault]: MetricsServiceDefault[K];
};
