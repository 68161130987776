// This file contains the public interface types for this component. Since this
// component uses TS strict mode, which other TS components may not, we keep
// the types for the public interface separate in order to avoid compilation
// errors arising from the strict mode mismatch.

export enum ErrorCode {
  UNKNOWN = 0,
  SESSION_INVALID = 1,
  ANSWER_INVALID = 2,
  WORKER_LOAD_ERROR = 3
}

/**
 * The parameters required to render a Proof-of-Work challenge.
 */
export type ChallengeParameters = {
  containerId: string;
  sessionId: string;
  appType?: string;
  renderInline: boolean;
  shouldDynamicallyLoadTranslationResources: boolean;
  onChallengeCompleted: OnChallengeCompletedCallback;
  onChallengeInvalidated: OnChallengeInvalidatedCallback;
  onModalChallengeAbandoned: OnModalChallengeAbandonedCallback | null;
};

/*
 * Callback Types
 */
export type OnChallengeCompletedData = {
  redemptionToken: string;
};

export type OnChallengeInvalidatedData = {
  errorCode: ErrorCode;
};

export type OnChallengeCompletedCallback = (data: OnChallengeCompletedData) => unknown;

export type OnChallengeInvalidatedCallback = (data: OnChallengeInvalidatedData) => unknown;

export type OnModalChallengeAbandonedCallback = (restoreModal: () => void) => unknown;

/**
 * The type of `renderChallenge`.
 */
export type RenderChallenge = (challengeParameters: ChallengeParameters) => Promise<boolean>;
