/* eslint-disable no-console */
import { UrlParser } from 'Roblox';
import { ActionType } from '../captcha/interface';
import * as TwoStepVerification from '../twoStepVerification';
import { LOG_PREFIX } from './app.config';
import { ChallengeType } from './interface';

const APP_TYPE_UNKNOWN = 'unknown';

/**
 * The key constants for different query parameters.
 */
enum QueryParameterKey {
  ACTION_TYPE = 'action-type',
  ALLOW_REMEMBER_DEVICE = 'allow-remember-device',
  APP_TYPE = 'app-type',
  CAPTCHA_ID = 'captcha-id',
  CHALLENGE_ID = 'challenge-id',
  CHALLENGE_TYPE = 'challenge-type',
  DARK_MODE = 'dark-mode',
  DATA_EXCHANGE_BLOB = 'data-exchange-blob',
  SESSION_ID = 'session-id',
  USER_ID = 'user-id'
}

export type QueryParametersBase = {
  challengeType: ChallengeType;
  appType: string;
  darkMode: boolean;
};

/**
 * Reads query parameters to determine which hybrid challenge to render.
 */
export const readQueryParametersBase = (): QueryParametersBase | null => {
  const queryParameters = UrlParser.getParametersAsObject();
  const {
    [QueryParameterKey.CHALLENGE_TYPE]: challengeTypeRaw,
    [QueryParameterKey.DARK_MODE]: darkModeRaw
  } = queryParameters;
  let { [QueryParameterKey.APP_TYPE]: appType } = queryParameters;

  // Validate string parameters.
  if (appType === undefined) {
    appType = APP_TYPE_UNKNOWN;
    console.warn(LOG_PREFIX, 'Got no app type');
  }

  // Validate dark mode parameter.
  const darkMode = darkModeRaw === 'true';
  if (darkModeRaw !== 'true' && darkModeRaw !== 'false') {
    console.error(LOG_PREFIX, 'Got invalid dark mode:', darkModeRaw);
    return null;
  }

  // Validate `ChallengeType` parameter.
  const challengeType = challengeTypeRaw as ChallengeType;
  if (!Object.values(ChallengeType).includes(challengeType)) {
    console.error(LOG_PREFIX, 'Got invalid challenge type:', challengeTypeRaw);
    return null;
  }

  return { challengeType, appType, darkMode };
};

export type QueryParametersForCaptcha = {
  actionType: ActionType;
  dataExchangeBlob: string;
  unifiedCaptchaId: string;
};

/**
 * Reads query parameters to render a hybrid captcha challenge.
 */
export const readQueryParametersForCaptcha = (): QueryParametersForCaptcha | null => {
  const queryParameters = UrlParser.getParametersAsObject();
  const {
    [QueryParameterKey.ACTION_TYPE]: actionTypeRaw,
    [QueryParameterKey.DATA_EXCHANGE_BLOB]: dataExchangeBlob,
    [QueryParameterKey.CAPTCHA_ID]: unifiedCaptchaId
  } = queryParameters;

  // Validate DX blob.
  if (dataExchangeBlob === undefined) {
    console.error(LOG_PREFIX, 'Got invalid DX blob:', dataExchangeBlob);
    return null;
  }
  // Validate unified captcha ID.
  if (unifiedCaptchaId === undefined) {
    console.error(LOG_PREFIX, 'Got invalid unified captcha ID:', unifiedCaptchaId);
    return null;
  }

  // Validate `ActionType` parameter.
  const actionType = actionTypeRaw as ActionType;
  if (!Object.values(ActionType).includes(actionType)) {
    console.error(LOG_PREFIX, 'Got invalid captcha action type:', actionTypeRaw);
    return null;
  }

  return { actionType, dataExchangeBlob, unifiedCaptchaId };
};

export type QueryParametersForTwoStepVerification = {
  userId: string;
  challengeId: string;
  actionType: TwoStepVerification.ActionType;
  allowRememberDevice: boolean;
};

/**
 * Reads query parameters to render a hybrid 2SV challenge.
 */
export const readQueryParametersForTwoStepVerification = (): QueryParametersForTwoStepVerification | null => {
  const queryParameters = UrlParser.getParametersAsObject();
  const {
    [QueryParameterKey.USER_ID]: userId,
    [QueryParameterKey.CHALLENGE_ID]: challengeId,
    [QueryParameterKey.ACTION_TYPE]: actionTypeRaw,
    [QueryParameterKey.ALLOW_REMEMBER_DEVICE]: allowRememberDeviceRaw
  } = queryParameters;

  // Validate user ID.
  if (userId === undefined) {
    console.error(LOG_PREFIX, 'Got invalid user ID:', userId);
    return null;
  }
  // Validate challenge ID.
  if (challengeId === undefined) {
    console.error(LOG_PREFIX, 'Got invalid challenge ID:', challengeId);
    return null;
  }

  // Validate `ActionType` parameter.
  const actionType = actionTypeRaw as TwoStepVerification.ActionType;
  if (!Object.values(TwoStepVerification.ActionType).includes(actionType)) {
    console.error(LOG_PREFIX, 'Got invalid 2SV action type:', actionTypeRaw);
    return null;
  }

  // Validate remember device parameter.
  const allowRememberDevice = allowRememberDeviceRaw === 'true';
  if (allowRememberDeviceRaw !== 'true' && allowRememberDeviceRaw !== 'false') {
    console.error(LOG_PREFIX, 'Got invalid remember device:', allowRememberDeviceRaw);
    return null;
  }

  return { userId, challengeId, actionType, allowRememberDevice };
};

export type QueryParametersForSecurityQuestions = {
  userId: string;
  sessionId: string;
};

/**
 * Reads query parameters to render a hybrid security questions challenge.
 */
export const readQueryParametersForSecurityQuestions = (): QueryParametersForSecurityQuestions | null => {
  const queryParameters = UrlParser.getParametersAsObject();
  const {
    [QueryParameterKey.USER_ID]: userId,
    [QueryParameterKey.SESSION_ID]: sessionId
  } = queryParameters;

  // Validate user ID.
  if (userId === undefined) {
    console.error(LOG_PREFIX, 'Got invalid user ID:', userId);
    return null;
  }
  // Validate challenge ID.
  if (sessionId === undefined) {
    console.error(LOG_PREFIX, 'Got invalid session ID:', sessionId);
    return null;
  }

  return { userId, sessionId };
};

export type QueryParametersForProofOfWork = {
  sessionId: string;
};

/**
 * Reads query parameters to render a hybrid proof-of-work challenge.
 */
export const readQueryParametersForProofOfWork = (): QueryParametersForProofOfWork | null => {
  const queryParameters = UrlParser.getParametersAsObject();
  const { [QueryParameterKey.SESSION_ID]: sessionId } = queryParameters;

  // Validate challenge ID.
  if (sessionId === undefined) {
    console.error(LOG_PREFIX, 'Got invalid session ID:', sessionId);
    return null;
  }

  return { sessionId };
};
