import { TranslationConfig } from 'react-utilities';

export const FEATURE_NAME = 'Captcha' as const;
export const LOG_PREFIX = 'Captcha:' as const;

/**
 * Translations required by this web app (remember to also edit
 * `bundle.config.js` if changing this configuration).
 */
export const TRANSLATION_CONFIG: TranslationConfig = {
  common: [],
  feature: 'Authentication.Captcha'
};

/**
 * Language resource keys for captcha that are requested dynamically.
 */
export const CAPTCHA_LANGUAGE_RESOURCES = [
  'Action.PleaseTryAgain',
  'Action.Reload',
  'Header.RobotChallenge',
  'Message.Error.Default'
] as const;
